@import '../../../Color.scss';

.encounters {
    .white-card {
        background-color: '#FFFFFF';
        padding: 10;
        flex-direction: 'row';
        display: flex;
        cursor: pointer;
    }
    .vertical-timeline-element-date {
        color: #000 !important;
        opacity: 1 !important;
    }
    .icon-on-card:hover {
        color: $primary-color;
    }
    .no-data {
        text-align: center;
        margin-top: 100px;
        color: white;
        font-size: 26px;
    }
    @media screen and (min-width: 1170px) {
        .vertical-timeline-element-date {
            color: #ffffff !important;
        }
    }
}
