@import '../../../Color.scss';

.veteranhistory {
    .item {
        .item-content {
            margin: 12px;
            padding: 12px;
            border-radius: 8px;
            text-align: center;
            background-color: $secondary-color;
        }
    }
}

.item-list-heading{
    font-weight: bold;
}