.white-container.addyourdoctor {
    padding: 28px;
    & > div {
        display: flex;
        align-items: center;
        .common-button {
            width: 138px;
            margin-left: 20px;
        }
    }
}
