.title{
    font-family: "Montserrat" !important;
    font-size: 36px !important;
    font-weight: normal;
    line-height: 35px;
    font-style: normal;
    text-align: left;
    color: #000000; 
}
.subTitle{
    margin-top: 11px;
    font-family: "Montserrat" !important;
    font-size: 13px !important;
    font-weight: normal;
    line-height: 17px;
    font-style: normal;
    color: #858585;

}