@import 'Color';
// @font-face {
//     font-family: 'Montserrat';
//     src: local('Montserrat'), url('../public/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
// }

// @font-face {
//     font-family: 'Montserrat';
//     font-style: italic;
//     font-weight: variable;
//     // font-family: 'Montserrat-Italic';
//     src: local('Montserrat'), url('../public/fonts/Montserrat-Italic-VariableFont_wght.ttf') format('truetype');
// }

body {
    // background-color: #2a7cce !important;
    margin: 0 !important;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: -apple-system, BlinkMacSystemFont, 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    @media only screen and (max-width: 1427px) {
        margin: 0 !important;
    }
    #root {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.iframe-size {
    position: fixed;
    background: #f2f2f2;
    width: 100%;
    height: 100%;

    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;

    transform: translate(-50%, -50%);
}
.modal-size {
    position: fixed;
    background: #f2f2f2;
    width: 90%;
    height: 90%;

    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;

    transform: translate(-50%, -50%);
}

.column-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.row-center-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.row-center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.row-center-around {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.primary-color {
    color: $primary-color;
}

._container {
    flex: 1;
    width: 100%;
    .container-right {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        .main-title {
            font-size: 26px;
            font-weight: 400;
            padding: 12px 10px;
            color: $yellow-color;
            line-height: initial;
            width: 50%;
            &.with-icon {
                display: flex;
                align-items: center;
                span {
                    margin-right: 12px;
                }
                img {
                    cursor: pointer;
                }
                svg {
                    margin-right: 12px;
                }
            }
        }
        .disability-rating {
            font-size: 26px;
            font-weight: 400;
            padding: 12px 20px 0px;
            color: $yellow-color;
            line-height: initial;
            width: 50%;
            float: right;
            text-align: right;
            align-items: baseline;
            &.with-icon {
                display: flex;
                align-items: right;
                span {
                    margin-right: 12px;
                }
                img {
                    cursor: pointer;
                }
                svg {
                    margin-right: 12px;
                }
            }
        }
        .white-container {
            flex: 1;
            overflow-y: auto;
            border-radius: 4px;
            margin-bottom: 1.5rem;
            background-color: #ffffff;
            border-top-left-radius: 28px;
            &.padding-28 {
                padding: 28px;
            }
            @media screen and (max-width: 992px) {
                margin-bottom: 1rem;
            }
        }
    }
}

/* custom scrollbar */
.custom-scroll {
    max-height: 88%;
    overflow-y: scroll;
}
.custom-scroll::-webkit-scrollbar {
    width: 15px;
  }
  
  .custom-scroll::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .custom-scroll::-webkit-scrollbar-thumb {
    background-color: #208b9c;
    // background-color: #0131FF;
    border-radius: 20px;
    border: 5px solid transparent;
    background-clip: content-box;
    min-height: 46px;
    max-height: 46px;
  }
  
  .custom-scroll::-webkit-scrollbar-thumb:hover {
    // background-color: #2e89da;
    background-color: #208b9c ;
  }

  
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: #9fadb4;
    border-radius: 8px;
    border: 1px solid #CFD8DC;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #78909C;
}

/* common button */
.common-button {
    height: 36.5px;
    min-width: 120px !important;
    color: #ffffff !important;
    text-transform: none !important;
    background-color: $secondary-color !important;
    &.disabled {
        color: rgba(0, 0, 0, 0.26) !important;
        background-color: rgba(0, 0, 0, 0.12) !important;
    }
}

/* close icon */
.close-icon {
    width: 70px;
    cursor: pointer;
    color: $red-color;
}
.edit-icon {
    width: 70px;
    cursor: pointer;
    color: $gray-color;
    margin-right: 5px;
}

.MuiFormControl-root {
    width: 100%;
}

/* radio */
.MuiRadio-colorPrimary.Mui-checked {
    color: $primary-color !important;
}

.table-info-icon {
    cursor: pointer;
    color: $primary-color;
}

legend {
    float: unset !important;
}

.custom-row {
    display: flex;
    flex-direction: row;
    .side-sec {
        width: 240px;
    }
    .main-sec {
        flex: 1;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
}

.policyList li a{
    color:#176571
}
.termsList li a{
    color:#176571
}
@media screen and (max-width: 992px) {
    .custom-row {
        display: block;
        .side-sec {
            margin-left: 1rem;
            margin-right: 1rem;
            width: calc(100% - 2rem);
        }
        .main-sec {
            margin-left: 1rem;
            margin-right: 1rem;
            width: calc(100% - 2rem);
            height: calc(100% - 255px);
        }
    }
}
