@import '../../../Color.scss';

._container.createdependant {
    .profile-image {
        background-image: url('../../../assets/profiles/userlogo.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        width: 250px;
        height: 250px;
        margin: 10px;
        border: 2px solid #000000;
        cursor: pointer;
    }
    .plan-image {
        background-repeat: no-repeat;
        background-size: cover;
        width: 70%;
        height: 250px;
        margin: auto;
        border: 1px solid #000000;
        cursor: pointer;
    }
    .save-button {
        background-color: $secondary-color !important;
        color: #fff !important;
        width: 100px;
        border-radius: 176px !important;
        text-transform: none !important;
    }
    .cancel-button {
        background-color: #dfdfdf !important;
        color: #000 !important;
        width: 100px;
        border-radius: 176px !important;
        text-transform: none !important;
    }
}
