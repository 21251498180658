.white-container.importrecords {
    .import-sec {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        &.bluebutton-sec, &.veteran-sec {
            margin-top: 36px;
        }
        // &.veteran-sec {
        //     // justify-content: end;
            
        // }
        .common-button {
            width: 130px;
            line-height: 10pt;
            // 120
        }
    }
}
