@import '../../../Color.scss';

.side-sec {
    border-radius: 4px;
    background-color: #fff;
    & > div:not(.menu) {
        width: '100%';
        display: flex;
        cursor: pointer;
        font-weight: 500;
        padding: 10px 15px;
        border-radius: 4px;
        &.active {
            background-color: $secondary-color;
            svg,
            span {
                color: #fff;
            }
        }
        &:not(.active):hover {
            background-color: lighten(#000, 90%);
        }
        svg {
            color: $primary-color;
        }
        span {
            margin-left: 10px;
        }
        .expandmoreicon {
            margin-left: 10px;
            margin-right: 47px;
        }
    }
    .menu {
        & > div {
            width: '100%';
            display: flex;
            cursor: pointer;
            font-weight: 500;
            padding: 10px 48px;
            &.active {
                background-color: $secondary-color;
                svg,
                span {
                    color: #fff;
                }
            }
            &:not(.active):hover {
                background-color: lighten(#000, 90%);
            }
            svg {
                color: $primary-color;
            }
            span {
                margin-left: 10px;
            }
        }
    }
}
