@import '../../../Color.scss';

._container.claims {
    .card {
        margin: 10px;
        width: 180px;
        height: 180px;
        padding: 20px;
        box-shadow: 1px 2px 5px grey;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        border-radius: 10px;
    }
    .icon-card {
        color: $secondary-color;
        font-size: 80px !important;
    }
    .title-card {
        margin-top: 20px;
        font-size: 22px;
    }
}
