@import '../../../Color.scss';

._container.findprovider {
    .provider-photo-male-finddoctor {
        background-image: url('../../../assets/profiles/male.png');
        /* border: 1px solid $primary-color; */
        background-repeat: no-repeat;
        background-size: cover;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        margin: 0 10px;
    }

    .provider-photo-female-finddoctor {
        background-image: url('../../../assets/profiles/female.png');
        /* border: 1px solid $primary-color; */
        background-repeat: no-repeat;
        background-size: cover;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        margin: 0 10px;
    }

    .left-side {
        height: calc(100% - 79px);
    }

    .view-all:hover {
        cursor: pointer;
        color: $primary-color;
    }

    .menuitems div {
        display: block;
        padding: 20px;
        transition: all 0.3s ease;
        color: #000;
        font-size: 18px;
    }

    .menuitems div:hover,
    .menuitems div.active {
        background-color: $secondary-color;
        color: white;
        cursor: pointer;
    }

    .menuitem {
        border-top: 1px solid #dfdfdf;
        text-decoration: none;
    }
}
