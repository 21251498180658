@import '../../../Color.scss';

.white-container.yourclaims {
    .claims-container {
        .checkicon {
            font-size: 30px;
            color: $primary-color;
        }

        .icon-group {
            display: flex;
            align-items: center;
            .email-icon {
                margin-right: 12px;
            }
        }
    }
}
