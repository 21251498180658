@import '../../../Color.scss';

.white-container.myprofile {
    position: relative;

    .profile-image {
        width: 210px;
        height: 210px;
        cursor: pointer;
        margin: 0 auto 20px;
        background-size: cover;
        border: 2px solid #000000;
        background-repeat: no-repeat;
        background-image: url('../../../assets/profiles/userlogo.jpg');
    }
    input[type='file'] {
        display: none;
    }
    .research {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .infoIcon {
        cursor: pointer;
        color: #007ccf;
    }
    .button-sec {
        margin-top: 20px;
        text-align: center;
    }
    .dir-column {
        display: flex;
        flex-direction: column;
    }
    .patient {
        width: 50%;
        display: inline-block;
    }
    .delete {
        display: inline-block;
    }
    .deleteButton {
        color: 'error';
    }

    .fontInsurance{
        font-family: 'Montserrat';
        font-weight: normal;
        line-height: 18px;
    }
}
