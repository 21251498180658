._container.appointments {
    .profile-pic {
        width: 100px;
    }
    .profile-pic-video {
        width: 50px;
        border-radius: 50%;
        background-color: #000000;
    }
    .userRow {
        position: relative;
        bottom: 100%;
        padding: 10px;
        color: #ffffff;
    }
    .profile-name {
        margin-top: 15px;
        font-size: 18px;
        color: black;
        margin-bottom: 5px;
        letter-spacing: 0.5px;
    }
    .category-name {
        margin-top: 0px;
        font-size: 15px;
        color: #ababab;
        margin-bottom: 0px;
        letter-spacing: 0.5px;
    }
    h4 {
        margin-top: 0px !important;
        color: #9c9c9c;
    }
    .hand {
        cursor: pointer;
    }
}
