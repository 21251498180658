.white-container.sharemyrecords {
    .photo {
        width: 36px;
        height: 36px;
        margin: 0 10px;
        border-radius: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        &.male {
            background-image: url('../../../assets/profiles/male.png');
        }
        &.female {
            background-image: url('../../../assets/profiles/female.png');
        }
    }
}
