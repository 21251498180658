.dependents {
    .male-photo {
        background-image: url('../../../assets/profiles/male.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        margin: 0 10px;
    }
    .female-photo {
        background-image: url('../../../assets/profiles/female.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        margin: 0 10px;
    }
}
